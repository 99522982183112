const company = url => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'GoGift',
  alternateName: ['GoGift.com A/S', 'Gavekortet.dk', 'Presentkortet.se'],
  url,
  logo: 'https://shop.gogift.com/images/4b49026b29d2e786539200ee85203475.png',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+46 10 138 84 44',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'SE',
      availableLanguage: ['en', 'sv', 'fi', 'nb', 'nn', 'no', 'da', 'de'],
    },
    {
      '@type': 'ContactPoint',
      telephone: '+45 70 89 00 60',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'DK',
      availableLanguage: ['en', 'da', 'sv', 'nb', 'nn', 'no', 'fi'],
    },
    {
      '@type': 'ContactPoint',
      telephone: '+358 20 7528020',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'FI',
      availableLanguage: ['en', 'da', 'sv', 'nb', 'nn', 'no', 'fi'],
    },
    {
      '@type': 'ContactPoint',
      telephone: '+47 22 33 05 00',
      contactType: 'customer service',
      contactOption: 'TollFree',
      areaServed: 'NO',
      availableLanguage: ['en', 'da', 'sv', 'nb', 'nn', 'no', 'fi'],
    },
  ],
  sameAs: [
    'https://www.facebook.com/GoGiftDK/',
    'https://www.linkedin.com/company/gogift/',
  ],
});

export default company;
